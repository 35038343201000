import { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/style.scss";
import LoaderGif from "./Components/Loader";
import { GenderContext } from "./Context/GenderThemeContext";
import { ChangeTheme } from "./assets/css/GenderTheme";
import PaymentPopup from "./Components/PaymentPopup";
import ResetPassword from "./Pages/ResetPassword";
import Privacy from "./Pages/privacy";
import Terms from "./Pages/terms";
import MoneyBackGuarantee from "./Pages/moneyBackGuarantee";
import { Typography } from "@mui/material";
import Notfound from "./Components/notfound";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import axios from "axios";
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const DeleteAccountFlow = lazy(() => import("./Pages/DeleteAccountFlow"));
const GatherInformation = lazy(() => import("./Pages/GatherInformation"));

const firebaseConfig = {
  apiKey: "AIzaSyCElUzd-N3U4ohK6liEysRGWa4YRYUAM6g",
  authDomain: "fitnessplanner-99aef.firebaseapp.com",
  projectId: "fitnessplanner-99aef",
  storageBucket: "fitnessplanner-99aef.firebasestorage.app",
  messagingSenderId: "536672937005",
  appId: "1:536672937005:web:671d8d60e68d388ccd84a4"
};

const res = await axios.get("https://api.ipify.org/?format=json");
global.ip = res.data.ip;
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
global.docRef = doc(db, 'getapp', 'fitnessplanner', new Date().toLocaleDateString("en-CA"), global.ip);
setDoc(global.docRef, { click: window.location.search }, { merge: true });

function App() {
  const { gender } = useContext(GenderContext); // Getting gender from context

  useEffect(() => {
    ChangeTheme(gender);
  }, [gender]);

  // remove below code once done
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Suspense
      fallback={
        <>
          <LoaderGif />
        </>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/male" replace />} />
          <Route path="/male" element={<GatherInformation type={"male"} />} />
          <Route path="/female" element={<GatherInformation type={"female"} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/money-back" element={<MoneyBackGuarantee />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/delete-account-flow" element={<DeleteAccountFlow />} />
          {/* <Route path="/popup" element={<PaymentPopup />} /> */}
          <Route path="*" element={<Navigate to="/male" replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
